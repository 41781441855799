<template>
    <div>
        <h1>Sensor Reports</h1>

        <div v-if="!loading">
          <div v-if="reports.length > 0">
            <br/>
            <div v-for="report in reports" :key="report.id">
              <router-link :to="`/device/${$route.params.id}/reports/${report.periodStartTimestamp}`">

                <p>{{ report.period }} starting {{new Date(report.periodStartTimestamp * 1000).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '-')}}</p>

              </router-link>
            </div>
          </div>
          <div v-else>
            <br/>
            <p>No reports found.  Reports are generated weekly, check back soon.</p>
          </div>
        </div>
        <div v-else class="mt-6 loading">
          <v-progress-linear indeterminate :color="siteColor"></v-progress-linear>
        </div>
    </div>

  </template>

  <script>

  export default {
    name: "Reports",
    props: [],
    components: {

    },
    data() {
      return {
        sensorUuid: this.$route.params.id,
        loading: true,
        reports: []
      };
    },
    computed: {

    },
    async mounted() {
      let sensor;
      try {

        sensor = await this.$store.dispatch("sensor/getSensorByUuid", {
        uuid: this.sensorUuid
      });
      } catch (err) {
        return;
      }

      this.reports = await this.$store.dispatch("sensor/getSensorReports", {
          sensorId: sensor.id
        });
      this.loading = false;
    },
    methods: {

    }
  };
  </script>
